import { Link } from 'gatsby';
import React from 'react';
import { ICONS } from '../utils/icons';
import MainContainer from '../components/main-container';

const linkCSS =
    'w-full flex flex-row items-center justify-start ' +
    'px-3 py-2 ' +
    'text-blue-900 bg-white hover:bg-gray-100 ' +
    'transition-colors duration-100 ';

export default function Page() {
    return (
        <MainContainer indexPage>
            <div className='relative flex flex-col max-w-xs mb-4 overflow-hidden bg-white rounded shadow sm:max-w-full sm:flex-row'>
                <img
                    src='/portrait.jpg'
                    className='relative w-full h-auto sm:w-65 sm:h-78'
                    alt='me'
                    width='765'
                    height='918'
                />

                <div className='flex flex-col items-start justify-center px-4 py-4 sm:px-6 sm:w-96 no-selection'>
                    <h1 className='w-full text-center text-rose-600 sm:text-left'>
                        Moritz Makowski
                    </h1>
                    <p className='mb-4 text-center text-gray-800 break-word font-weight-500 sm:text-left'>
                        Studying Engineering Science and Computer Science at{' '}
                        <a
                            className='font-weight-600'
                            href='https://www.tum.de/en/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            TUM
                        </a>{' '}
                        in Munich. Coding a lot on the side - mostly in
                        web-development.
                    </p>
                    <a
                        className={
                            'flex flex-row items-center justify-center mb-3/2 ' +
                            'text-gray-800 hover:text-rose-600 ' +
                            'transition-colors duration-100 cursor-pointer'
                        }
                        href='mailto:moritz@dostuffthatmatters.dev'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <div className='flex-shrink-0 w-5 h-5 mx-1/2 opacity-60'>
                            {ICONS.email}
                        </div>
                        <div className='ml-1 font-weight-600 mb-1/2'>
                            moritz@dostuffthatmatters.dev
                        </div>
                    </a>
                    <a
                        className={
                            'flex flex-row items-center justify-center mb-3/2 ' +
                            'text-gray-800 hover:text-rose-600 ' +
                            'transition-colors duration-100 cursor-pointer'
                        }
                        href='https://github.com/dostuffthatmatters'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <div className='flex-shrink-0 w-5 h-5 opacity-60 mx-1/2'>
                            {ICONS.github}
                        </div>
                        <div className='ml-1 mb-1/2 font-weight-600'>
                            dostuffthatmatters
                        </div>
                    </a>
                </div>
            </div>
            <div
                className={
                    'overflow-hidden bg-white rounded shadow ' +
                    'flex flex-col md:flex-row items-center justify-center'
                }
            >
                <Link to='/lectures' className='w-full md:w-auto'>
                    <div className={linkCSS}>
                        <div className='w-6 h-6 opacity-60'>{ICONS.school}</div>
                        <div className='ml-2 mr-1 font-weight-600 mb-1/2'>
                            Lectures I've taken
                        </div>
                    </div>
                </Link>
                <Link to='/skills' className='w-full md:w-auto'>
                    <div
                        className={
                            linkCSS +
                            'border-t-2 border-b-2 md:border-t-0 md:border-b-0 ' +
                            'border-gray-300 md:border-l-2 md:border-r-2 '
                        }
                    >
                        <div className='w-6 h-6 opacity-60'>{ICONS.tools}</div>
                        <div className='ml-2 mr-1 font-weight-600 mb-1/2'>
                            Technologies I use
                        </div>
                    </div>
                </Link>
                <Link to='/projects' className='w-full md:w-auto'>
                    <div className={linkCSS}>
                        <div className='w-6 h-6 opacity-60'>{ICONS.code}</div>
                        <div className='ml-2 mr-1 font-weight-600 mb-1/2'>
                            Things I work on
                        </div>
                    </div>
                </Link>
            </div>
        </MainContainer>
    );
}
